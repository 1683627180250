.container {
  background: #f0f0f0;
  /* cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39, auto; */
  display: flex;
  /* align-items:center; */
  padding-top:300px;

  /* justify-content: center; */
}

.content {
  cursor: pointer;
  position: relative;
  transform: perspective(50rem) rotateX(3deg);
  margin-left:20px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);

}

.content > div {
  position: absolute;
  height: 350px;
  transform-origin: 50% 50% 0px;
  border-radius: 10px;
  color: white;
  line-height: 40px;
  padding-left: 32px;
  font-size: 14.5px;
  background: lightblue;
  text-transform: uppercase;
  letter-spacing: 2px;
  touch-action: none;
  border: 0.5px solid rgb(25, 25, 25);
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
}
.content > div {
  background-size: cover;
}

